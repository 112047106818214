<template lang="pug">
    #permissions-try-again
      .modal-header
        h2 Something went wrong acquiring your camera/microphone

      .modal-text
        p If this problem persists, please
          |
          |
          a(:href="emailLink") contact support for assistance
          | .

      .modal-footer
        button.button.action-btn(@click="performAction") Try Again
  </template>

<script>
export default {
  props: {
    retryAction: {
      type: Function,
      required: true,
    },
    emailSubject: {
      type: String,
      required: false,
      default: 'Kit app help request',
    },
  },

  data() {
    return {}
  },

  computed: {
    emailLink,
  },

  watch: {},

  methods: {
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function emailLink() {
  return `mailto:kit.help@ro.co?subject=${this.emailSubject}`
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.retryAction()
  this.$modal.close('PermissionsTryAgain')
}
</script>
